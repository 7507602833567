@media (min-width: 320px) and (max-width: 999px) {
    li{
        font-size: smaller;
    }

    

    }

.header {

    margin-right: 3rem;
    display: flex;
    justify-content: center;
    font-size: large;
    max-width: 100vw;
}

.list {
    list-style: none;
    display: flex;
    gap: .7rem;
    font-family: 'Linotte';
}

.list a {
    text-decoration: none;
    color: var(--color-primary-400);
}

.list a:hover,
.list a.active{
    color: red;
    text-shadow: 5;
}

.listItem{
    display: inline-block;
}

.home{
    padding-bottom: 1rem;
  }